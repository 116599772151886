.table-container {
  margin: 20px;
}

.pagination-container {
  margin-top: 10px;
}

.pagination {
  justify-content: flex-end;
}

.search-input {
  margin-top: 10px;
  float: right;
  width: 100%;
}
.search-input > .search {
  float: right;
  width: 240px;
}

#addBtn {
  width: 60px;

  background: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 1px;

  color: #ffffff;
}

#addBtn:hover {
  background: #333232;
}

.hovered-row {
  background-color: #f5f5f5;
}

.table-image-container {
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
}
.table-image-container1 {
  margin: auto;
  width: 150px;
  height: 50px;
  object-fit: contain;
  overflow: hidden;
}

.table-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.table-image1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
}

.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 25px;
  color: #000;

  width: 100%;
  margin: auto;
}

#AddBtn {
  padding: 5px;
  text-decoration: none;
  border-radius: 3px;
}
#AddBtn:hover {
  background: #f5f5f5;
  color: black;
}

.popup-container {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  border: 1px solid rgb(68, 67, 67);
  border-radius: 5px;
  padding: 20px;
}

.popup p {
  margin: 0;
}

.popup-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-button {
  margin-right: 10px;

  padding: 5px 10px;
}

.popup-button.verify {
  background-color: green;
  color: white;
  border-radius: 3px;
}

.popup-button.cancel {
  background-color: red;
  color: white;
  border-radius: 3px;
}
