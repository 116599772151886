.quicklikCard {
  filter: drop-shadow(0px 0px 0px rgb(246, 244, 244));
  transition: all 0.5s;
}

.quicklikCard:hover {
  filter: drop-shadow(1px 1px 2px rgb(104, 104, 104));
}

/* .quicklikCardImg {
  filter: drop-shadow(2px 2px 2px rgb(167, 167, 166));
  -webkit-filter: drop-shadow(2px 2px 2px rgb(167, 167, 166));
} */

.punchinBtn {
  width: 9em;
  height: 2.7em;
  border-radius: 30em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.punchinBtn::before {
  content: '';
  width: 0;
  height: 3em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #0fd850 0%, #f9f047 100%);
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.punchinBtn:hover::before {
  width: 9em;
}

.birthday-card {
  /*border: 1px solid red;
  */
  z-index: 111111;
  max-width: auto;
  max-height: auto;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  /* animation: floatUpDown 2s ease-in-out infinite; */
}
.birthday-card:hover {
  animation-play-state: paused; /* Pause the animation on hover */
}

.top-right-link {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
  font-size: 15px;
  color: #007bff;
}
/* Birthday message */
.birthday-message {
  font-size: 17px;
  margin-bottom: 20px;
  color: #ff004f;
  font-weight: 600;
}

.birthday_image_container {
  display: flex;
  justify-content: center;
}
/* Birthday image */
.birthday-image {
  width: 120px;
  height: 120px;
  object-fit: fill;
  border-radius: 50%;
}

/* Footer text */
.footer-text {
  font-size: 13px;
  color: #555;
}
.footer-text > .msgContainer {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.footer-text > .msgContainer > .Submit {
  background: #ff20ec;
  /* font-size: 15px; */
  border: none;
  outline: none;
  color: #fff;

  padding: 1px 6px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.footer-text .msgBox {
  height: 30px;
  max-width: 220px;
  margin: auto;
  outline: none;
  border: none;
}

.footer-text .msgBox:focus {
  outline: none;
  border: none;
}

.Loginbutton {
  width: 6.5em;
  height: 1.8em;
  margin: 0.5em;
  background: black;
  color: white;
  border: none;
  border-radius: 0.625em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.Loginbutton:hover {
  color: black;
}

.Loginbutton:after {
  content: '';
  background: white;
  position: absolute;
  z-index: -1;
  left: -20%;
  right: -20%;
  top: 0;
  bottom: 0;
  transform: skewX(-45deg) scale(0, 1);
  transition: all 0.5s;
}

.Loginbutton:hover:after {
  transform: skewX(-45deg) scale(1, 1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
