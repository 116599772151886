@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated {
  animation: fadeIn 0.5s forwards;
}
.notice-container {
  width: 80%;
  margin: 20px auto;
  padding: 10px 20px;
  border: 1px solid rgb(201, 201, 201);
  min-height: 50vh;
}
.notice-header {
  text-align: center;
}
.notice-subject {
  text-align: right;
  font-weight: bold;
}
.highlight-points li {
  list-style-type: square;
}
.notice-footer {
  text-align: left;
  margin-top: 20px;
}
.company-seal {
  width: 100px;
  height: 130px;
}

.marquee-container {
  overflow: hidden; /* This will hide any overflowing content */
  width: 100%; /* Ensure it spans the full width */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  /* padding-left: 100%; */
  /*Starttheanimationoutsideofthecontainer*/
  animation: marquee 15s linear infinite;
  -webkit-animation: marquee 15s linear infinite;
  color: rgb(255, 0, 0);
}

.attachment {
  margin-top: 15px;
  display: flex;

  flex-wrap: wrap;
}

.attachment > .inner-attachment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 4px;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.attachment > .inner-attachment > img {
  height: 80px;
  width: 80px;
  /* margin-right: 10px; */
  object-fit: contain;
}
.attachment > span {
  text-decoration: none;
  cursor: pointer;
}

.editBtn {
  background: transparent;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  /* padding: 3px 5px 3px 5px; */
  margin: 0px 3px 0px 3px;
  text-decoration: none;
  font-size: 17px;
  color: #1934df;
}
.viewBtn {
  background: transparent;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  /*padding: 3px 5px 3px 5px;
  */
  margin: 0px 3px 0px 3px;
  text-decoration: none;
  font-size: 17px;
  color: #0be219;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
}

.deleteBtn {
  background: transparent;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  /* padding: 3px 5px 3px 5px; */
  margin: 0px 3px 0px 3px;
  text-decoration: none;
  font-size: 17px;
  color: #f30909;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
}
