.main-container {
  max-width: 95vw;
  margin-left: 60px;
  min-height: 80vh;
  /* border: 1px solid red; */
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.main-container > .imageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card-container {
  perspective: 1000px; /* Create a 3D effect */
}

.jobCard {
  width: 190px;
  min-height: 200px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}
