.Manualcard {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  min-height: 180px;
  display: flex;
  flex-direction: column;
}
.manualicon {
  font-size: 130px;
  padding: 10px;
}
