.sidebar {
  width: 50px;
  min-height: 100vh;

  background-color: #fafafa;
  /*background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  */
  transition: width 0.3s;
  overflow: auto;
  z-index: 100;
  position: fixed;
  /*margin-top: 4px;
  */
  top: 0;
  left: 0;
  /*border-right: 1px solid black;
  */
  filter: drop-shadow(2px 2px 2px rgb(104, 104, 104));
  /*border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  */
  bottom: 0;
  -webkit-filter: drop-shadow(2px 2px 2px rgb(104, 104, 104));
}

.sidebar::-webkit-scrollbar {
  width: 3px; /* Set the width of the scrollbar */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #d7d5d5; /* Set the color of the thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}

.sidebar::-webkit-scrollbar-track {
  background-color: #fafafa; /* Set the color of the track */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the thumb on hover */
}

.sidebar.expanded {
  width: 180px;
}

.sidebar h1,
.sidebar p {
  /* margin: 10px; */
}

.sidebar:hover,
.sidebar.expanded {
  width: 180px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.sidebar i {
  font-size: 1.7rem;
  margin-right: 10px;
  transition: margin-right 0.3s;
}

.sidebar span {
  font-size: 1.1rem;
  transition: opacity 0.5s;
  opacity: 0;
  white-space: nowrap; /* Added */
  overflow: hidden; /* Added */
  text-overflow: ellipsis; /* Added */
}

.sidebar:hover span {
  opacity: 1;
}

.sidebar a {
  padding: 10px;
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  font-size: 1.3rem;
  width: 100%;
  margin: auto;
  white-space: nowrap; /* Added */
  overflow: hidden; /* Added */
  text-overflow: ellipsis; /* Added */
}

.sidebar .icon {
  /* margin: 1px; */
  padding: 1px;
  font-size: 1.2rem;
}

.sidebar a:hover {
  margin: 5px;
  text-decoration: none;
  color: #010101;
  background: #e5e5e5;
  font-weight: 500;
  font-size: 1.3rem;
  width: 100%;
  margin: auto;
}

.sidebar .expanded {
  color: #000000;
}

.sidebar > .logo > h1 {
  display: flex;
  justify-content: center;
  font-size: 25px;
  margin: 10px;
  font-family: 'Raleway', sans-serif;
  color: #000000;
}

.sidebar .logo .heading2 {
  display: none;
}

.sidebar:hover .logo .heading2 {
  display: block;
  z-index: 1000;
}

.sidebar:hover .logo .heading1 {
  display: none;
}

.sidebar > ul > li > .icon {
  font-size: 2px;
}

.sidebar:hover li i {
  margin-right: 20px;
}
