.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 100%;
  z-index: 100;
  position: fixed;
  /* margin-top: 4px; */
  top: 0;
}

.navbar-brand {
  font-size: 18px;
  font-weight: bold;
}

.navbar-nav {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-item {
  margin-right: 10px;
}

.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown1 img {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

#dropimg {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
}

.dropdown-menu1 {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fafafa;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  filter: drop-shadow(5px 5px 5px rgb(104, 104, 104));
  padding: 10px;
  width: 245px;
  border-radius: 5px;
}

.dropdown-item1 {
  display: block;
  padding: 8px;
  color: #333;
  text-decoration: none;
}

.dropdown-item1:hover {
  background-color: #f3f3f3;
  color: rgb(22, 18, 18);
  border-radius: 5px;
}
/* 
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  border: 1px solid rgb(68, 67, 67);
  border-radius: 5px;
  padding: 30px;
}

.popup p {
  margin: 0;
}

.popup-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-button {
  margin-right: 10px;

  padding: 5px 10px;
}

.popup-button.verify {
  background-color: green;
  color: white;
  border-radius: 3px;
}

.popup-button.cancel {
  background-color: red;
  color: white;
  border-radius: 3px;
} */

/* Use media queries to adjust styles for mobile devices */
@media screen and (max-width: 767px) {
  .navbar {
    width: 100%;
  }

  .popup-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup {
    background-color: white;
    border: 1px solid rgb(68, 67, 67);
    border-radius: 5px;
    padding: 0px; /* Reduce the padding for mobile devices */
    max-width: 30%; /* Limit the width for mobile devices */
    /* overflow-y: auto; Add vertical scrollbar if the content exceeds the height */
  }

  .popup-buttons {
    margin-top: 20px; /* Increase the margin for mobile devices */
    display: flex;
    flex-direction: row; /* Stack the buttons vertically on mobile devices */
    justify-content: center;
    align-items: center;
  }

  .popup-button {
    margin-bottom: 10px; /* Add some spacing between buttons */
    padding: 5px 5px;
    width: 50%; /* Make the buttons full width on mobile devices */
  }
}
