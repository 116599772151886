.gallery {
  /* border: 1px solid #ccc; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 20px;
  background-color: #fff;
}

.large-image img {
  width: 100%;
  height: 350px;
  object-fit: contain;
  border: 1px solid #ccc;
}

.thumbnail-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.thumbnail:hover {
  border-color: blue;
}

.downloadBtn {
  margin: 10px;
  --width: 80px;
  --height: 35px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 18px;
  --button-color: #6c18ff;

  --tooltip-color: #fff;
  width: var(--width);
  height: var(--height);
  background: var(--button-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em;
  font-family: 'Arial';
  transition: background 0.3s;
}

.downloadBtn::before {
  position: absolute;
  content: attr(data-tooltip);
  width: var(--tooltip-width);
  height: var(--tooltip-height);
  background-color: var(--tooltip-color);
  font-size: 0.9rem;
  color: #111;
  border-radius: 0.25em;
  line-height: var(--tooltip-height);
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
  left: calc(50% - var(--tooltip-width) / 2);
}

.downloadBtn::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: var(--tooltip-color);
  left: calc(50% - 10px);
  bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
}

.downloadBtn::after,
.downloadBtn::before {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.text1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-wrapper,
.text1,
.icon1 {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
}

.text1 {
  top: 0;
}

.text1,
.icon1 {
  transition: top 0.5s;
}

.icon1 {
  color: #ffffff;
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon1 > .svg {
  width: 24px;
  height: 24px;
}

.downloadBtn:hover {
  background: #6c18ff;
  cursor: pointer;
}

.downloadBtn:hover .text1 {
  top: -100%;
}

.downloadBtn:hover .icon1 {
  top: 0;
}

.downloadBtn:hover:before,
.downloadBtn:hover:after {
  opacity: 1;
  visibility: visible;
}

.downloadBtn:hover:after {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.downloadBtn:hover:before {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}
