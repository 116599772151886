.main-container {
  max-width: 95vw;
  margin-left: 60px;
  min-height: 80vh;
  /* border: 1px solid red; */
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.main-container > .imageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.main-container > .imageContainer > img {
  height: 80px;
  width: 200px;
  object-fit: contain;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* .directorCard {
  border: none;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  background: #ffffff;
  color: #000000;
  min-height: 270px;
} */

.Department:hover {
  cursor: pointer;
  background: #2a2a2a;
}

.card-container {
  perspective: 1000px; /* Create a 3D effect */
}

.directorCard {
  width: 180px;
  min-height: 300px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.7s;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.directorCard:hover {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hide the backface to prevent flickering */
}

.card-back {
  transform: rotateY(180deg);
  /* Add styles for the back side of the card */
  display: flex;

  justify-content: center;
  align-items: center;
}

.card-back > .email {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  text-align: justify;
  flex-wrap: wrap;
  font-size: 12px;
  margin: 0px 10px;
}

.card-back > .chat {
  font-size: 16px;
  text-decoration: none;
  background-color: rgb(233, 228, 228);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);

  padding: 0px 15px;

  margin: 0px 10px;
  border-radius: 5px;
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
}

.card-back > .chat:hover {
  background-color: rgb(166, 162, 162);
  color: #0000;
}
