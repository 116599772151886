@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400&display=swap');
@import 'nprogress/nprogress.css';
#nprogress .bar {
  height: 4px;
  border-top: 4px solid rgba(255, 0, 128, 1) !important;
  z-index: 110; /* Higher than the navbar */
  position: fixed; /* Ensure it's positioned */
  top: 0;
  /* other styles... */
}

#nprogress .bar .peg {
  box-shadow: 0 0 2px #fff, 0 0 5px #fff !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Recursive', sans-serif;
}
body {
  min-height: 80vh;
}

.app {
  position: relative;
}

/* a {
  text-decoration: none;
  font-weight: 600;
  color: #000;
}
a:hover {
  color: #000;
} */

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.dashboard {
  position: absolute;
  top: 0;
  left: 10;
  z-index: 2;
}

/* CSS */
.progress-line {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  width: 0;
  background-color: #007bff; /* Replace with your desired color */
  z-index: 9999;
  transition: width 0.3s ease-in-out;
}

.container {
  margin: 59px 0px 0px 52px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-container {
  margin: 59px 0px 0px 52px;
  z-index: 12;
  opacity: 100;
  background-color: #a6a6a6;
}

.small-container {
  /* margin-left: 52px;
  margin-top: 59px; */
  max-width: 950px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 59px auto 0px 52px;
  min-height: 10vh;
  /* border: 1px solid black; */
  z-index: 1;
}

.container1 {
  margin-left: 55px;
  margin-top: 59px;
  z-index: 1;
  /* border: 1px solid red; */
  /* padding: 10px; */
}

.container3 {
  height: 100vh;
}

.container4 {
  margin: 59px 0px 0px 52px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container5 {
  margin-left: 52px;
  margin-top: 59px;
  z-index: 1;
}
/* .navlinks {
  margin-left: 5px;
  margin-top: 59px;
} */

input:focus {
  outline: none;
  border: 1px solid #ccc; /* Optional: You can set a different border color if desired */
}

.submitBtn {
  background: rgb(0, 0, 0);
  border: none;
  border-radius: 1px;
  cursor: pointer;
  padding: 3px 5px 3px 5px;
  margin: 5px;
  text-decoration: none;

  color: #ffffff;
}

.submitBtn:hover {
  background: rgb(41, 40, 40);
  color: rgb(255, 255, 255);
  /* border: 1px solid rgb(255, 255, 255); */
}

.submitBtn2 {
  background: rgb(0, 0, 0);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px;
  margin: 10px;
  text-decoration: none;

  color: #ffffff;
}

.submitBtn2:hover {
  background: rgb(41, 40, 40);
  color: rgb(255, 255, 255);
  /* border: 1px solid rgb(255, 255, 255); */
}

.underline {
  display: inline-block;
  width: 30%;
  /* border-bottom: 2px solid #000000; */
  /* margin-top: 5px; */
  margin: 5px auto 0px auto;
}

.historyBtn {
  background: rgb(220, 239, 54);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px;
  text-decoration: none;
  margin: 10px;
  width: 15%;
  color: #050505;
}
.historyBtn:hover {
  color: #050505;
}

/* .alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: 30rem;
} */
/* .alert1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: 30rem;
} */

.alert-danger {
  background: rgb(248, 59, 59);
  color: white;
}

.alert-success {
  background: rgb(102, 237, 30);
  color: rgb(3, 3, 3);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%; /* Adjust the height as needed */
}

.closeBtn {
  background: rgb(255, 255, 255);
  color: #ffffff;
  font-weight: 900;
  outline: none;
  border: none;
  border-radius: 50%;
  padding: 1px;
  font-size: 17px;
}

/* .dashboard {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }
} */

.closeBtn {
  background: transparent;
  outline: none;
}

.selectOption:focus {
  outline: none;

  background-color: #fff;
  border: 1px solid #a6a6a6;
  /* border-radius: 4px; */
  /* outline: none; */
}

input {
  width: 270px;

  height: 35px;
  padding: 10px 10px;
  background-color: #fff;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  outline: none;
}

.input {
  width: 270px;

  height: 35px;
  /* padding: 10px 10px; */
  background-color: #fff;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  outline: none;
}
.input1 {
  width: 270px;

  height: 45px;
  padding: 10px 10px;
  background-color: #fff;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  outline: none;
}

.input2 {
  width: 20px;

  height: 45px;
  background-color: #fff;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  outline: none;
}
.input3 {
  width: 15px;

  background-color: #fff;
  border: 1px solid #a6a6a6;

  outline: none;
}

.small-image-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.structureImg {
  width: 600px;
  height: 400px;
  object-fit: contain;
}

.table-responsive::-webkit-scrollbar {
  height: 8px; /* Set the width of the scrollbar */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #b5b1b1; /* Set the color of the thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #d4d4d4; /* Set the color of the track */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the thumb on hover */
}

a {
  text-decoration: none;
  font-weight: 700;
  color: crimson;
}
