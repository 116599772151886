* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signin {
  background-image: url(./product.jpg);
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  border: 0.1px solid transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.taypro-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.form_main {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 30px 30px 30px 30px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
  position: relative;
  overflow: hidden;
}

.form_main::before {
  position: absolute;
  content: '';
  width: 330px;
  height: 310px;
  background-color: rgb(209, 193, 255);
  transform: rotate(45deg);
  left: -180px;
  bottom: 30px;
  z-index: 1;
  border-radius: 30px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.082);
}

.heading {
  font-size: 2em;
  color: #2e2e2e;
  font-weight: 700;
  margin: 5px 0 10px 0;
  z-index: 2;
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.inputIcon {
  position: absolute;
  left: 3px;
}

.inputField {
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  padding-right: 30px;
  border-bottom: 2px solid rgb(173, 173, 173);
  margin: 10px 0;
  color: black;
  font-size: 0.8em;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 30px;
}

.inputField2 {
  width: 80%;
  height: 35px;
  background-color: transparent;
  border: 1px solid rgb(199, 114, 255);
  outline: none;
  padding-right: 30px;
  margin: 10px 0;
  color: black;
  font-size: 0.8em;
  font-weight: 500;
  box-sizing: border-box;
}

.inputField3 {
  width: 100%;
  height: 35px;
  background-color: transparent;
  border: 1px solid rgb(199, 114, 255);
  outline: none;
  padding-right: 30px;
  /* margin: 10px 0; */
  color: black;
  font-size: 0.8em;
  font-weight: 500;
  box-sizing: border-box;
}

.inputField2:focus {
  outline: none;

  border: 2px solid rgb(199, 114, 255);
}

.inputField:focus {
  outline: none;
  border-bottom: 2px solid rgb(199, 114, 255);
}

.inputField::placeholder {
  color: rgb(80, 80, 80);
  font-size: 1em;
  font-weight: 500;
}

#button {
  z-index: 2;
  position: relative;
  width: 100%;
  border: none;
  background-color: rgb(162, 104, 255);
  height: 30px;
  color: white;
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 10px;
  cursor: pointer;
}

#button:hover {
  background-color: rgb(126, 84, 255);
}

.forgotLink {
  z-index: 2;
  font-size: 0.9em;
  font-weight: 500;
  color: rgb(44, 24, 128);
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 20px;
}

.password-wrapper {
  width: 100%; /* Add this */
  position: relative;
  display: inline-block;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

h4 {
  text-decoration: none;
}
