.container {
  margin-left: 52px;
  margin-top: 59px;
  z-index: 1;
  /* border: 1px solid red; */
  /* padding: 10px; */
}

#profileImg {
  filter: drop-shadow(10px 10px 10px rgb(104, 104, 104));
}

.employee-form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 5px;
}

h4 {
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.role-row {
  display: flex;
  flex-wrap: wrap; /* This will make sure the items wrap to next line */
  gap: 1rem;
}

.role-item {
  flex: 1;
  max-width: 200px; /* or another appropriate value */
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-control:focus {
  outline: none;
  border: none;
  /* border-bottom: 2px solid rgb(199, 114, 255); */
}

.form-control {
  /* border: none; */
  /* border-bottom: 2px solid rgb(199, 114, 255); */
}

.NewBtn {
  padding: 5px 15px;
  background: #fc129f;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-top: 15px;
  display: block;
  margin-left: auto;
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -ms-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
}

.NewBtn:hover {
  color: #fff;
}
