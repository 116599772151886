.tooltip-container {
  /*--background-tooltip-cont/ainer: #2ea44f;
  */
  position: relative;
  background: #373737;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 17px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.tooltip {
  --background-tooltip: #6e7681; /* Default background color for tooltip */
  position: absolute;
  top: -59px;
  left: 100%;
  transform: translateX(-50%);
  padding: 0.5em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  background: var(--background-tooltip);
  color: white; /* Text color */
  border-radius: 5px;
  min-width: 200px;
  min-height: 30px;
  font-size: 13px;
  text-align: center;
}

.tooltip::before {
  position: absolute;
  content: '';
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(
    --background-tooltip
  ); /* Use the same background color as the tooltip */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
