.cssbuttons-io-button {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 15px;
  padding: 0.8em 1.5em 0.8em 1.2em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgb(120, 47, 255) 0%,
    rgb(185, 132, 255) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em rgb(184, 146, 255);
  letter-spacing: 0.01em;
  border-radius: 20em;
}

.cssbuttons-io-button svg {
  margin-right: 8px;
}

.cssbuttons-io-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em rgb(149, 91, 255);
}

.cssbuttons-io-button:active {
  box-shadow: 0 0.3em 1em -0.5em rgb(160, 109, 255);
}

/* Modal */
.modal {
  z-index: 1050; /* Ensure this is higher than the backdrop */
}

/* Modal Backdrop */
.modal-backdrop {
  z-index: 1040; /* Ensure this is lower than the modal z-index */
}
